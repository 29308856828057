import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints, QueryFilter } from "../Models/api";
import {
  AWSCredentialResponseModel,
  AccessControlStateUpdateModel,
  AudioSettingsModel,
  DeviceEventSettingModel,
  DeviceNetworkSettingsModel,
  DoorSetting,
  VideoSettings,
} from "../Models/devices";
import { CancelToken } from "axios";
import AWS from "aws-sdk";
import { ClientConfiguration } from "aws-sdk/clients/s3";
import { promisify } from "util";
import qs from "qs";

export const getAllDevices = (
  filter: QueryFilter,
  token?: CancelToken,
  authToken?: string,
  signal?: AbortSignal
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/devices",
    method: axiosMethods.get,
    params: filter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    cancelToken: token,
    signal: signal,
    headers: authToken
      ? {
          Authorization: `Bearer ${authToken}`,
        }
      : undefined,
  });
};

export const getAllVideoSources = (filter: QueryFilter, token?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/videosources/paged",
    method: axiosMethods.get,
    params: filter,
    cancelToken: token,
  });
};

export const deleteADevice = (serial: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serial,
    method: axiosMethods.delete,
  });
};

export const moveDeviceLocation = (serial: string[], zoneId: number, all?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.zoneManagement + "/zone/" + zoneId + "/devices",
    method: axiosMethods.post,
    params: {
      all: all,
    },
    data: serial,
  });
};

export const updateDevicesName = (serialNumber: string, name: string) => {
  const data = {
    name,
  };
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber,
    method: axiosMethods.put,
    data,
  });
};
export const getTheDevice = (serialNumber: string, includeSiteInfo?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber,
    method: axiosMethods.get,
    params: {
      includeSiteInfo,
    },
  });
};
export const setLockState = (newAccessControlState: AccessControlStateUpdateModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/devices/access-control-state",
    method: axiosMethods.post,
    data: newAccessControlState,
  });
};

export const rebootDevice = (serialNumber: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/reboot/" + serialNumber,
    method: axiosMethods.post,
  });
};

export const getDoorHealthCheck = (serialNumber: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/healthcheck/" + serialNumber,
    method: axiosMethods.get,
  });
};

export const resetNetworkSettingsDevice = (serialNumber: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/reset-network-settings/" + serialNumber,
    method: axiosMethods.post,
  });
};
export const setNetworkSettingsDevice = (
  serialNumber: string,
  data: DeviceNetworkSettingsModel
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/network-settings/" + serialNumber,
    method: axiosMethods.post,
    data: {
      ip_address: data.ipAddress,
      netmask: data.netmask,
      default_gateway: data.defaultGateway,
      ip_mode: data.ipMode,
      dns_server: data.dnsServer,
      wifi_ssid: data.wifi_ssid,
      wifi_key: data.wifi_key,
    },
  });
};

export const updateDeviceZone = (
  serialNumber: string,
  primaryZoneId: number,
  secondaryZoneId: number
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/zones/" + serialNumber,
    method: axiosMethods.put,
    data: {
      primaryZoneId,
      secondaryZoneId,
    },
  });
};

export const getDeviceWebRTCCredentials = (serialNumber: string, token?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/credentials/" + serialNumber,
    method: axiosMethods.get,
    cancelToken: token,
  });
};

export const getDeviceIdentificationWorkflows = (serialNumber: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/identification-workflows",
    method: axiosMethods.get,
    params: {
      serialNumber,
    },
  });
};

export const updateDeviceIdentificationWorkflows = (serialNumber: string, data: number[][]) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/identification-workflows",
    method: axiosMethods.put,
    params: {
      serialNumber,
    },
    data,
  });
};

export const getDoorSettings = (serialNumber: string, token?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/access-control-settings/" + serialNumber,
    method: axiosMethods.get,
    cancelToken: token,
  });
};

export const updateDoorSettings = (data: DoorSetting) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/access-control-settings/",
    method: axiosMethods.post,
    data: data,
  });
};

export const updateFaceSettingsInTheDoor = (
  serialNumber: string,
  publishUnknownUsers: boolean,
  publishNonLiveUsers: boolean,
  publishAuthenticatedUsers: boolean,
  enrollFaceViaCard: boolean,
  enableLivenessCheck: boolean,
  livenessMode: number
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/face-settings",
    method: axiosMethods.put,
    data: {
      publishUnknownUsers,
      publishNonLiveUsers,
      publishAuthenticatedUsers,
      enrollFaceViaCard,
      enableLivenessCheck,
      livenessMode,
    },
  });
};

export const getNetworkSettings = (serialNumber: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/network-settings/" + serialNumber,
    method: axiosMethods.get,
  });
};

export const getAudioSettings = (serialNumber: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/audio-settings/" + serialNumber,
    method: axiosMethods.get,
  });
};

export const updateAudioSettings = (serialNumber: string, data: AudioSettingsModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/audio-settings/" + serialNumber,
    method: axiosMethods.post,
    data: data,
  });
};

export const updateNotificationSettings = (
  serialNumber: string,
  sendPushNotifications: boolean
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/notification-settings",
    method: axiosMethods.post,
    params: {
      sendPushNotifications,
    },
  });
};

export const updateVideoSettingsInTheDoor = (
  serialNumber: string,
  videoSettings: VideoSettings
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/video-settings",
    method: axiosMethods.put,
    data: {
      ...videoSettings,
    },
  });
};

export const getDoorCombinedSettings = (serialNumber: string, cancelToken: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/face-video-settings",
    method: axiosMethods.get,
    cancelToken: cancelToken,
  });
};

export const getOnWallDevices = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/devices/on-wall",
    method: axiosMethods.get,
  });
};

export const modifyOnWallDevice = (serial: string, isOnWall: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serial + "/on-wall/" + isOnWall,
    method: axiosMethods.put,
  });
};

export const getTheListOfDevices = (serialNumbers: string[]) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/devices/multiple",
    method: axiosMethods.post,
    data: serialNumbers,
  });
};

export const getLastImageDevices = (serialNumbers: string[], cancelToken: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/devices/last-images",
    method: axiosMethods.post,
    data: serialNumbers,
    cancelToken: cancelToken,
  });
};
//deprecated
export const getEventVideoHLS = (
  serialNumber: string,
  time: string,
  cancelToken: CancelToken,
  duration?: number
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/video-stream",
    method: axiosMethods.get,
    params: {
      serialNumber,
      time,
      duration,
    },
    cancelToken: cancelToken,
  });
};
//deprecated
//https://medium.com/@drevets/you-cant-prompt-a-file-download-with-the-content-disposition-header-using-axios-xhr-sorry-56577aa706d6
export const downloadVideo = (
  serialNumber: string,
  time: string,
  cancelToken: CancelToken,
  duration?: number
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/video-stream-download",
    method: axiosMethods.get,
    params: {
      serialNumber,
      time,
      duration,
    },
    cancelToken: cancelToken,
    responseType: "blob",
  });
};

//deprecated
export const shareEventVideo = (
  serialNumber: string,
  time: string,
  linkExpirationInSeconds: number,
  email: string,
  phoneNumber: string,
  deviceName: string,
  cancelToken: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/share-video-stream",
    method: axiosMethods.post,
    params: {
      serialNumber,
      time,
      expires: linkExpirationInSeconds,
      email,
      phoneNumber,
      deviceName,
    },
    cancelToken: cancelToken,
  });
};

export const getRTSPDoorSetting = (serialNumber: string, cancelToken?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/rtsp-settings",
    method: axiosMethods.get,
    cancelToken: cancelToken,
  });
};

export const postRTSPDoorSetting = (
  serialNumber: string,
  isRtspEnabled: boolean,
  rtspUsername?: string | null,
  rtspPassword?: string | null,
  cancelToken?: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/rtsp-settings",
    method: axiosMethods.post,
    params: {
      isRtspEnabled,
    },
    data: {
      rtsp_username: rtspUsername,
      rtsp_password: rtspPassword,
    },
    cancelToken: cancelToken,
  });
};

export const getEventsEventLogs = (
  serialNumber: string,
  queryFilter: QueryFilter,
  cancelToken?: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/event-logs",
    method: axiosMethods.get,
    params: queryFilter,
    cancelToken: cancelToken,
  });
};

export const getEventsLogsVideoSource = (
  videoSourceId: string,
  queryFilter: QueryFilter,
  cancelToken?: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/videosource/" + videoSourceId + "/event-logs",
    method: axiosMethods.get,
    params: queryFilter,
    cancelToken: cancelToken,
  });
};

export const getDeviceSettings = (serialNumber: string, cancelToken?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/event-settings",
    method: axiosMethods.get,
    cancelToken: cancelToken,
  });
};

export const updateEventSettings = (
  serialNumber: string,
  events: DeviceEventSettingModel,
  cancelToken?: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/event-settings",
    method: axiosMethods.put,
    data: events,
    cancelToken: cancelToken,
  });
};

export const getEventsArtifacts = (
  serialNumber: string,
  queryFilter: QueryFilter,
  cancelToken?: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/event-artifacts",
    method: axiosMethods.get,
    params: queryFilter,
    cancelToken: cancelToken,
  });
};
export const getEventsArtifactsImages = (artifactsIds: string[], cancelToken?: CancelToken) => {
  const temp = artifactsIds.join(",");
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/event-log/artifacts/image",
    method: axiosMethods.get,
    params: { artifacts: temp },
    cancelToken: cancelToken,
  });
};

export const checkPartialSerialNumber = (partialSerial: string, cancelToken?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.customerSupportManagement + "/device/" + partialSerial + "/verify",
    method: axiosMethods.post,
    cancelToken: cancelToken,
  });
};

export const addNewDevice = (serialNumbers: string[], cancelToken?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/device",
    method: axiosMethods.post,
    data: {
      deviceSerialNumbers: serialNumbers,
    },
    cancelToken: cancelToken,
  });
};

export const cameraAutoFocus = (serialNumber: string, cancelToken?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/lensposition/restore",
    method: axiosMethods.post,
    cancelToken: cancelToken,
  });
};

export const getDevicesPerAccount = (accountId: number) => {
  return axiosCall(axiosInstance, {
    url: endPoints.customerSupportManagement + "/devices/account",
    method: axiosMethods.get,
    params: {
      accountId,
    },
  });
};

export const getCloudStorangeImage = async (
  accountId: string,
  serialNumber: string,
  imageTitle: string,
  credentials: AWSCredentialResponseModel,
  isCacheControl?: boolean
) => {
  const objectKey = accountId + "/" + serialNumber + "/images/" + imageTitle + ".jpg";
  const awsConfig: ClientConfiguration = {
    region: credentials.credential.region,
    credentials: {
      accessKeyId: credentials.credential.accessKeyId,
      secretAccessKey: credentials.credential.secretAccessKey,
      sessionToken: credentials.credential.sessionToken,
    },
    endpoint: process.env.REACT_APP_CloudStorange_URL,
  };
  const s3 = new AWS.S3(awsConfig);
  const params = {
    Bucket: credentials?.credential.bucketName,
    Key: objectKey,
    ResponseCacheControl: isCacheControl ? "no-cache,  must-revalidate" : undefined,
  };

  // Promisify the getObject function
  const getObjectPromise: any = promisify(s3.getObject.bind(s3));
  return await getObjectPromise(params);
};

export const getCloudStorangeVideo = async (
  pathName: string,
  credentials: AWSCredentialResponseModel
) => {
  const objectKey = pathName;
  const awsConfig: ClientConfiguration = {
    region: credentials.credential.region,
    credentials: {
      accessKeyId: credentials.credential.accessKeyId,
      secretAccessKey: credentials.credential.secretAccessKey,
      sessionToken: credentials.credential.sessionToken,
    },
    endpoint: process.env.REACT_APP_CloudStorange_URL,
  };
  const s3 = new AWS.S3(awsConfig);
  const params = {
    Bucket:
      credentials?.credential.bucketName ||
      (process.env.REACT_APP_CloudStorange_BucketName as string),
    Key: objectKey,
  };

  // Promisify the getObject function
  const getObjectPromise: any = promisify(s3.getObject.bind(s3));
  return await getObjectPromise(params);
};

export const getCompleteEvents = (queryFilter: QueryFilter, cancelToken?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/complete-event-artifacts",
    method: axiosMethods.get,
    params: queryFilter,
    cancelToken: cancelToken,
  });
};

export const getEventArtifactsVideoSource = (
  videoSourceId: string,
  queryFilter: QueryFilter,
  cancelToken?: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/videosource/" + videoSourceId + "/event-artifacts",
    method: axiosMethods.get,
    params: queryFilter,
    cancelToken: cancelToken,
  });
};

export const getArtifactsEventLog = (id: string, queryFilter: QueryFilter) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/event-log/" + id + "/paged",
    method: axiosMethods.get,
    params: queryFilter,
  });
};

export const shareVideoPlaylist = (
  serialNumber: string,
  from: string,
  to: string,

  linkExpirationInSeconds: number,
  email: string,
  phoneNumber: string,
  deviceName: string,
  cancelToken: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/share-video-playlist",
    method: axiosMethods.post,
    params: {
      serialNumber,
      from,
      to,
      expires: linkExpirationInSeconds,
      email,
      phoneNumber,
      deviceName,
    },
    cancelToken: cancelToken,
  });
};

export const getUrlWirelessLock = (signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/external",
    method: axiosMethods.post,
    signal: signal,
  });
};
